<template>
  <v-tooltip
    :bottom="!right || !bottom || !left"
    :right="right"
    :top="top"
    :left="left"
    :color="color"
    :disabled="false"
    :transition="transition"
    :max-width="MaxWidth"
  >
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <span v-if="custom">
          <slot></slot>
        </span>
        <span v-else>
          <span v-if="displayText"> {{ displayText }} </span>
          <span v-else>
            <code v-if="dots" class="max-text"><span>..</span></code>
            <v-icon
              v-else
              :small="!large"
              :color="IconColor"
              v-text="iconText"
            />
          </span>
        </span>
      </span>
    </template>
    <slot name="message"></slot>
    <span v-if="VHtml" :class="textClass" v-html="VHtml"></span>
    <span
      style="white-space: pre-line"
      v-if="text"
      :class="textClass"
      v-text="text"
    ></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "VueTooltip",
  props: {
    right: Boolean,
    top: Boolean,
    left: Boolean,
    dots: Boolean,
    large: Boolean,
    IconColor: String,
    VHtml: String,
    transition: {
      type: String,
      default: "slide-y-transition",
    },
    textClass: {
      type: String,
      default: "",
    },
    iconText: {
      type: String,
      default: "info",
    },
    custom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    MaxWidth: {
      type: Number,
      default: 400,
    },
    text: {
      default: "",
    },
    displayText: {
      type: [String, Boolean, Number],
      default: null,
    },
  },
};
</script>

<style lang="scss">
.max-text {
  position: relative;
  z-index: 2;
}
</style>
